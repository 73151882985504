.pie-chart-container{
  width:20vw;
  height:35vh;
  /* position: absolute; */
}

.pie-reset{
  position: absolute;
  /* float: right; */
  cursor: pointer;
  right: 42px;
  z-index: 1;
} 

.bar-chart-container{
width:20vw;
height:50vh;
}

.card-container {
max-height: 14vh
}

.chart-container{

width: 20vw;
height: 100%;
background-color: white;
}

.parent-chart-container{
position: absolute;
left: 0px;
top: 0px;
height: 100%;
}

.chart-tool{
top: 8em;
left: 1em; 
position: inherit;
}
.btn-close {
/* --bs-btn-close-bg: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSI0OCIgdmlld0JveD0iMCAwIDQ4IDQ4IiB3aWR0aD0iNDgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTMwLjgzIDMyLjY3bC05LjE3LTkuMTcgOS4xNy05LjE3LTIuODMtMi44My0xMiAxMiAxMiAxMnoiLz48cGF0aCBkPSJNMC0uNWg0OHY0OGgtNDh6IiBmaWxsPSJub25lIi8+PC9zdmc+) !important */
}
.layer-switcher {
top: 0.5em;
right: 7.5em;
}