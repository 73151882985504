
.map-container {
  /* width: 400px;
 height: 400px; */
 /* width: '100%'; 
 height: '100%'; */
 height: 100vh; 
/* width: 100vw; */
}

.query-container {
max-width: 400px;
}

.col-form-label{
min-width: 100px;
}

.query-container {
/* background-color: rgba(255, 255, 255, 0.8); */
/* border: 1px solid #ccc; */
top: 1.6em;
right: 5em;  /* padding: 5px; */
/* border-radius: 5px; */
/* display: none; */
position: absolute !important;

}
.layer-switcher{
top: 0.5em;
}

/* .custom-control-container {
position: absolute;
top: 10px;
left: 10px;
display: none;
}

.custom-control {
background-color: rgba(255, 255, 255, 0.8);
border: 1px solid #ccc;
padding: 5px;
border-radius: 5px;
} */

.search-tool {
background-image: url( '../../assets/search.svg' );


}
.buttonContainer{
background-color: white;
padding-bottom: 5px;
}

