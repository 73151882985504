@font-face {
  font-family: Poppins_bold;
  src: url("../fonts/Poppins/Poppins-Bold.ttf");
}
@font-face {
  font-family: Poppins_Regular;
  src: url("../fonts/Poppins/Poppins-Regular.ttf");
}

@media only screen and (max-width: 1280px) {
  .img_view{
    width: 120px !important;
  }
}

@media only screen and (max-width: 1023px) {
  .pg_title{
    font-size: 20px !important;
  }
  .desc_tumbuhan{
    display: none;
  }
  .nama_item{
    font-size: 15px;
   
  }
  .listBar{
    align-items: center;
    justify-content: start;
  }
  .img_view{
    min-width: 40px !important;
  }
  
}

@media only screen and (min-width: 900px) {
  #resp_bar{
    display: none !important;
  }
  
}

@media only screen and (max-width: 900px) {

  .leg1{
    font-size: 17px !important;
    margin-top: -23px !important;
  }

  .banner {
    border-bottom: none !important;
  }

  .btn_eksimport{
  margin-top: 15px !important;
  }

  .banner .main_logo_layout{
    width: 60px !important;
  
  }

  .children{
    min-height: 200vh !important;
  }

  .w3-teal{
display: none;
  }
  .banner  .custom-dropdown{
    display: none !important;
  }
  .resp_bar{
    display: block !important;
  }
  .pro_bar{
    min-width: 100px !important;
  }

  
}
@media only screen and (max-width: 768px) {
  .login_leftCard {
    display: none !important; /* Adjust the font size for mobile view */
  }
  fieldset {
    margin-left: 5% !important;
  }
  
  .contentqr img{
    width: 200px;
  }
  .leg1{
    font-size: 1.3em !important;
  }

  .big_title{
    font-size: 1.2em !important;
    margin-left: 30px !important;
  }
  .main_logo_layout{
    width: 50px !important;
  }

  .pg_title{
    font-size: 15px !important;
  }

  .modal_responsive{
    margin-left: 0px !important;
  }

  .eksport_btn, .import_btn, .qrGenBtn, .formSimpanBtn, .formBatalBtn,.formUpdateBtn, .saveQR_btn, .printQR_btn {
    font-size: 10px !important;
    height: 25px !important;
  }

  .addDisease .icon svg{
    width: 15px !important;
  }
  .addDisease .text{
    font-size: 20px !important;

  }

  
}

@media only screen and (max-width: 556px){
  .pagination{
    justify-content: start !important;
  }
  }


@media only screen and (max-width: 550px){
 #mySidebar2{
  display: block !important;
 }
 #mySidebar{
  display: none !important;
 }
 /* .w3-teal2{
width: 60px !important;
 } */

 /* .sidebar_scroll2 a .icon_sidebar i {
  font-size: 20px !important;
  
} */

/* .sidebar_scroll2 div{
  min-width:60px !important;
  
  } */

.sidebar_main_logo{
  width: 30px !important;
}
 
}

@media only screen and (max-width: 501px){
    .addZone{
      margin-bottom: 10px;
    }
  }

@media only screen and (max-width: 481px){
  .main_logo_layout{
    width: 40px !important;
  }
  .big_title{
    font-size: 1.0em !important;
    margin-left: 20px !important;
  }
  .contentqr img{
    width: 150px;
  }

  .generateBtnDiv{
    text-align: center !important;
  }
}
@media only screen and (max-width: 458px){
  
  .formSimpanBtn {
    margin-left: 5% !important;
    margin-bottom: 2% !important;
  }
}
@media only screen and (max-width: 440px){
  .main_logo_layout{
    width: 40px !important;
  }
  .big_title{
    font-size: 1.0em !important;
    margin-left: 10px !important;
    }

    .img_view{
      margin-left: -10px;
    }

    .leg1{
      font-size: 18px !important;
      margin-top: -23px !important;
    }
}

@media only screen and (max-width: 400px){
  .main_logo_layout{
    width: 30px !important;
  }
  .big_title{
    font-size: 0.8em !important;
    margin-bottom: 0px;
    
    }
    .listBar{

      flex-wrap: wrap;
    }  

    .img_view{
      margin-left: -15px;
    }

    .page-item button{
      font-size: 9.5px !important;
    }

    .leg1{
      font-size: 15px !important;
      margin-top: -23px !important;
    }
    
}

.children{
  /* margin-left: 100px; */
  max-width: 100%;
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 20px;
 
}
#main{
  background-color: #F7FFEF;
}

.bold_poppins {
  font-family: Poppins_bold;
}
.regular_poppins {
  font-family: Poppins_regular;
}

.login_layout{
  width: 70%;
  margin: 0 auto;
  margin-top: 1%;
  padding: 2%;
  overflow: auto !important;
}

fieldset {
  border: 2px solid #7B7159 !important;
  padding: 10px !important;
}

.card_layout{
  border: 2px solid #aba28f !important;
}

.leg1{
  background-color: #ffffff;
  color: #7B7159;
  margin: 5px;
  max-width: 30%;
  text-align: center;
  margin-top: -30px;
  font-weight: bold;
 font-size:  1.5em;
}

.main_logo{
  width: 40%;
}

.login_img{
  min-width: 200px !important; 
  min-height: 550px !important; 
  
}

.login_label{
  color: #7C6A59;
  font-family: Poppins_regular;
}

.login_input .form-control {
  background-color: #EEC671; 
  color: #333D14; 
  
}
.login_input input:focus {
  background-color: #EEC671; 
  color: #333D14; 
  outline: none;
  box-shadow: none;
}

.login_input span{
  background-color:  #EEC671 ;
  color: #333D14;
}

.login_input::placeholder {
    color: #333D14 !important;
    font-family: Poppins_Regular;
    font-size: 0.8em;
}
.login_btn {
  background-color: #405510 !important;
  font-family: Poppins_Regular !important;
}

.top_bar{
  display: flex; /* This activates flexbox */
  align-items: center; /* This vertically centers the items in the container */
  justify-content: start; /* This aligns items to the start of the main axis */
  gap: 10px;
}
.profile_top_bar{
  display: flex; /* This activates flexbox */
  align-items: center; /* This vertically centers the items in the container */
  justify-content: start; /* This aligns items to the start of the main axis */
  gap: 10px;
  /* background-color: #FBFBFB; */
}
.main_logo_layout{
  width: 70px;
}

.profile_pic{
  width: 50px; /* or height: 50px; assuming your image is 50x50px */
  height: 50px !important; /* Add this if your image isn't a square */
  border-radius: 50%;
  margin-right: 5px;
  border: 3px solid #68743D;
}
.profile_pic_resp{
  width: 30px; /* or height: 50px; assuming your image is 50x50px */
  height: 30px !important; /* Add this if your image isn't a square */
  border-radius: 50%;
  border: 3px solid #68743D;
}

.big_title{
  font-family: Poppins_Regular;
  font-size: 2.0em;
  margin-left: 50px;
  letter-spacing: 3px;
  text-shadow: 0px 3px 3px #bcbcbc;
  color:#98B14E;
  margin-top: 2%;
}

.base_fixed{
  position: fixed;
  width: 100% !important;
}

.admin-title{
  margin-bottom: 4px !important;
  margin-top: 10%;
  font-family: Poppins_regular;
}

.pro_bar{
  min-width: 120px;

}

.banner{
  border-bottom: 1px solid #000000;
}

.w3-teal, .w3-teal:hover{
  background-color: #FFFAEC !important;
  color: black !important;
  position: sticky !important;
  top:0 !important;
  z-index: 100 !important;
  margin-left: 1px !important;
  border: 0.5px solid #d4d4d4 !important;
  width: 80px !important;
  min-height: 50px;
}

.custom-dropdown .btn::after {
  content: none;
}

.custom-dropdown button{
  border: none !important;
  margin: 0 !important;
  /* text-shadow: 0px 4px 3px #515151; */
  padding: 0 !important;
}

.custom-dropdown button:focus {
  background: transparent !important;
  border: none !important;
  color: #333D14; 
  outline: none;
  box-shadow: none;
}
.sidebar_headerqr{
  overflow: auto;
  height: 88%;
}
#mySidebar, #mySidebar2{
  background-color: #FFFAEC;
  overflow-x: auto;
}

#mySidebar::-webkit-scrollbar,
#mySidebar2::-webkit-scrollbar {
  display: none;
}

.w3-teal2{
  color: #000000;
 width: 80px;
}

#resp_bar{
  background-color:#F0F3E5;
}

#resp_bar .resp_dropdown .custom-dropdown #dropdown-basic .profile_top_bar .pro_bar p{
  color: #000000;
  font-size: 0.6em;
}

.icon_sidebar{
  min-width: 80px !important;
  /* min-height: 45px !important; */
}

.sidebar_scroll2 a{
 width:auto;
  overflow-x: hidden;
  font-family: Poppins_regular;
}
.sidebar_scroll2 div{
min-width:80px ;

}
.sidebar_scroll2 a .icon_sidebar i {
  font-size: 25px ;
  
}

.sidebar_scroll2 a.active{
  background-color: #737C04;
  color: #FFFAEC !important;

}

.sidebar_scroll2 a.subActive{
  background-color: #737C04;
  color: #FFFAEC !important;

}

.sidebar_main_logo{
  width: 35px ;
}

.sidebardiv_main_logo {
  min-height: 50px;
}

.qr_icon2{
  width: 30px;
}

.qrGenBtn{
  background-color: #FF6F07 !important;
  color: #fff !important;
  width: 150px;
  box-shadow: 3px 2px 5px #a1a1a1;
  font-family: Poppins_Regular;
  font-weight: 500;
  letter-spacing: 2px;
  border-radius: 4px;
  height: 32px;
  border: none;
  outline: none;
  text-decoration: none;
  text-align: center;
}
.viewqrBtn{
  background-color: #BAFFC9 !important;
  color: #000000 !important;
  width: 150px;
  box-shadow: 3px 2px 5px #a1a1a1;
  font-family: Poppins_Regular;
  font-weight: 500 !important;
  letter-spacing: 2px;
  border-radius: 4px;
  height: 32px;
  border: none;
  outline: none;
  text-decoration: none;
  text-align: center;
}

.formSimpanBtn{
  background-color: #405510 !important;
  color: #fff !important;
  width: 150px;
  margin-right: 5%;
  /* height: 32px; */
  box-shadow: 3px 2px 5px #a1a1a1;
  font-family: Poppins_Regular;
  font-weight: 500;
  letter-spacing: 2px;
  border-radius: 4px;
  border: none;
  outline: none;
  text-decoration: none;
  text-align: center;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

.formBatalBtn {
  background-color: #B64403 !important;
  color: #fff !important;
  width: 150px;
  box-shadow: 3px 2px 5px #a1a1a1;
  font-family: Poppins_Regular;
  font-weight: 500;
  letter-spacing: 2px;
  border-radius: 4px;
  height: 32px;
  border: none;
  outline: none;
  text-decoration: none;
  text-align: center;
}

.formUpdateBtn {
  background-color: #59B2B2 !important;
  color: #fff !important;
  width: 150px;
  box-shadow: 3px 2px 5px #a1a1a1;
  font-family: Poppins_Regular;
  font-weight: 500;
  letter-spacing: 2px;
  border-radius: 4px;
  height: 32px;
  border: none;
  outline: none;
  text-decoration: none;
  text-align: center;

}

.saveQR_btn {
  background-color: #405510;
  color: #ffffff;
  font-family: Poppins_Regular;
  width: 150px;
  margin-right: 5%;
  margin-bottom: 5%;
  box-shadow: 3px 2px 5px #a1a1a1;
  border-radius: 4px;
  font-family: Poppins_Regular;
  font-weight: 600;
  letter-spacing: 2px;
  height: 32px;
  border: none;
  outline: none;
}

.printQR_btn {
  background-color: #036BB6;
  color: #ffffff;
  font-family: Poppins_Regular;
  width: 150px;
  margin-right: 5%;
  margin-bottom: 5%;
  box-shadow: 3px 2px 5px #a1a1a1;
  font-family: Poppins_Regular;
  font-weight: 600;
  border-radius: 4px;
  height: 32px;
  letter-spacing: 2px;
  border: none;
  outline: none;
  text-decoration: none;
  text-align: center;
}
.eksport_btn {
  background-color: #EFC66E;
  color: #2A3434;
  font-family: Poppins_Regular;
  width: 100px;
  margin-right: 2%;
  margin-bottom: 3%;
  box-shadow: 3px 2px 5px #a1a1a1;
  font-family: Poppins_Regular;
  font-weight: 600;
  border-radius: 4px;
  /* height: 32px; */
  letter-spacing: 2px;
  border: none;
  outline: none;
  text-decoration: none;
  text-align: center;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
.import_btn {
  background-color: #FEA134;
  color: #2A3434;
  font-family: Poppins_Regular;
  width: 100px;
  margin-right: 1%;
  margin-bottom: 3%;
  box-shadow: 3px 2px 5px #a1a1a1;
  font-weight: 600;
  border-radius: 4px;
  height: 32px;
  letter-spacing: 2px;
  border: none;
  outline: none;
  text-decoration: none;
  text-align: center;
}

.form_btn {
  justify-content: center;
  margin-left: 5%;
}

.formKembaliBtn {
  background-color: #59B2B2 !important;
  color: #fff !important;
  width: 150px;
  box-shadow: 3px 2px 5px #a1a1a1;
  font-family: Poppins_Regular;
  font-weight: 500;
  letter-spacing: 2px;
  border-radius: 4px;
  height: 32px;
  border: none;
  outline: none;
  text-decoration: none;
  text-align: center;

}

.table_dash{
  width: 1235px;
}

.addDisease{
  border: dotted;
  border-color: #969696;
  background: transparent;
  cursor: pointer;
  font-size: 1.5em;
  font-family: Poppins_regular;
}

.addDisease .icon .icon{
  font-size: 30px;
  color: #969696;
 }
 .addDisease .text{
  font-size: 20px;
  color: #969696;
 }

.listBar{
  background-color: #E8EAE0;
  border-radius: 10px;
}

.listBar:hover{
  background-color: #98B14E;
  border-radius: 10px;
  cursor: pointer;
}


.addZone{
  border: dotted;
  border-color: #969696;
  background: transparent;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 5px;
  color: #000000;
}

.btn_eksimport{
  margin-top: -30px ;
}

.img_view{
  width: 130px;
}

.editdelete_icon .ri-pencil-line{
font-size: large;
margin-right: 10px;
color: #0d9496;

}
.editdelete_icon .ri-delete-bin-line{
color: red;
font-size: large;
margin-right: 10px;
}
/* Modal Container */
.success-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure modal is on top */
}

/* Modal Content */
.modal-content {
  background-color: #fff; /* White background */
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 500px;;
  margin: auto;
}

.modal-header,
.modal-content {
  font-family: Poppins_Regular;
}

/* Close button */
.modal-content button {
  background-color: #6a7c59; /* Green color for button */
  color: white;
  border: none;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 4px;
}

.modal-content button:hover {
  background-color: #839f72; /* Darker green on hover */
}

.modal-content .view-button {
  background-color: #7c6a7f;
  color: white;
  border: none;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 4px;
}

.modal-content .view-button:hover {
  background-color: #8c7a8f; /* Darker green on hover */
}

.icon{
  padding-left: 10px;
  padding-right: 10px;
}

.edit-icon {
  color: #847887;
  cursor: pointer;
}

.delete-icon {
  color: #847887;
  cursor: pointer;
}

.label-left {
  text-align: left;
  width: 100%;
  display: block;
  font-family: Poppins_Regular;
}

.modal-content .delete-button {
  background-color: #d11a1b; /* Green color for button */
  color: white;
  border: none;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 4px;
}

.modal-content .delete-button:hover {
  background-color: #f03949;
}

.pagination-container {
  position: relative;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: -20px; /* Adjust as needed */
  margin-bottom: 20px;
}

.pagination button {
  background-color: #e0e0e0; /* Green color for button */
  color: #333;
  border: none;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin-top: 10px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 4px;
  font-family: Poppins_Regular;
}

.pagination button:hover {
  background-color: #c0c0c0;
}

.pagination button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.form-control,
.form-select {
  font-family: Poppins_Regular;
}

/* Table Styles */
.table-container {
  overflow: auto; /* Enable scrolling */
}

.table {
  width: 100%;
  border-collapse: separate;
  margin-bottom: 20px;
  background-color: #E8EAE0 !important;
  border-radius: 5px !important;
  font-family: Poppins_Regular;
}

.table th,
.table td {
  border: 0px solid #E8EAE0;
  padding: 8px;
  text-align: left;
}

.table th {
  background-color: #f2f2f2 !important;
}

.table tr {
  background-color: #e8eae0 !important;
}

.table tr td {
  border-color: #E8EAE0;
}

thead th {
  vertical-align: bottom;
  border-bottom: 0px solid #E8EAE0 !important;
}

.table td.text-center {
  white-space: nowrap;
}

.image-container {
  display: flex;
  width: 550px !important;
  overflow: auto;
}

.preview-container {
  margin-right: 10px;
  margin-bottom: 10px;
}

.preview-image {
  width: 150px;
  height: 150px;
  object-fit: fit;
  padding: 3px;
}

.remove-button {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
  border: 1px solid red;
  border-radius: 50%;
  font-size: 13px;
  margin-left: 5px;
}

.label_control{
  font-family: Poppins_Regular;
}
.table_responsive{
  overflow-x: auto;
}

.table_responsive thead th{
  background-color: #FFEDC5 !important;
  color: hsl(75, 97%, 28%);
  font-family: Poppins_Regular;
  border: none !important;
  white-space: nowrap;
}

.table_responsive tbody td{
  color: #8F9383 !important;
  white-space: nowrap;
}

.generateBtnDiv{
  text-align: end;
}

.logo_inQR{
  width: 25%;
  z-index: 1;
  margin-top: -15%;
  margin-left: -15%;
}

.preview-profile {
  overflow: hidden;
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

.preview-profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.qr_preview{
 width: 200px !important;
 height: 200px !important;
}

.slider_img{
  width: 200px !important;
  margin-bottom: 30px;
}

/* Map CSS */
body { margin: 0; }

.programCalendar{
  overflow: auto !important;
  width: 100% !important;
}

.event-list {
  list-style: none;
  padding: 0;
  margin-left: 0.8rem;
}

.event-item {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  position: relative; /* Needed for absolute positioning */
}

.event-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: -12px;
  width: 8px;
  height: 100%;
  background-color: #3d91ff;
  border-radius: 5px 0 0 5px;
}

.event-name {
  font-family: Poppins_Regular;
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 16px;
}

.event-dates {
  color: #666;
  font-family: Poppins_Regular;
}

.card-title {
  font-size: 22px;
  font-family: Poppins_bold;
}
.label-date {
  font-family: Poppins_Regular;
}
.clickable-event {
  cursor: pointer;
}
.formResetBtn{
  background-color: #847887 !important;
  color: #fff !important;
  width: 150px;
  margin-right: 5%;
  /* height: 32px; */
  box-shadow: 3px 2px 5px #a1a1a1;
  font-family: Poppins_Regular;
  font-weight: 500;
  letter-spacing: 2px;
  border-radius: 4px;
  border: none;
  outline: none;
  text-decoration: none;
  text-align: center;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

.formResetBtn:hover {
  background-color: #a39fa3 !important;
}

.formResetBtn:active {
  box-shadow: none;
}

/* CSS For Report sidebar menu */
.submenu {
  display: none;
}
.submenu ul {
  list-style-type: none;
  padding: 0;
}
.submenu ul li {
  padding: 0.5em;
}
.submenu ul li a {
  margin-left: 35%;
  color: #737c04!important;
}
.submenu ul li:hover {
  background-color: #a4ad38;
}
.submenu ul li:hover a {
  color: white!important;
  margin-left: 35%;
}
.text-dark:hover + .submenu,
.submenu:hover {
  display: block;
}
.head_menu:hover {
  background-color: #737c04;
  color: white!important;
}
.head_menu.clicked + .submenu {
  display: block;
}
.head_menu.clicked:hover {
  background-color: #737c04;
  color: white!important;
}
.head_menu.clicked:hover + .submenu {
  display: block;
}
.head_menu.clicked {
  background-color: #737c04;
  color: white !important;
}
.submenu ul li.clicked {
  background-color: #a4ad38;
}

.submenu ul li.clicked a{
  color: white !important;
}
.sidebar_scroll2 a .icon_submenu {
  font-size: 20px ;
  color: #737c04;
}

.submenu ul li.clicked a i{
  color: white !important;
}
.submenu ul li:hover a i{
  color: white!important;
}

.icon_profile {
  font-size: 38px ;
  color: #737c04;
  padding: 0.1em;
}
.resp_public {
  background-color: #70510c!important;
  height: 20px;
}
#public_main {
  background-color: white!important;
}
.small_title{
  font-family: Poppins_Regular;
  letter-spacing: 3px;
  text-shadow: 0px 3px 3px #bcbcbc;
  color:#98B14E;
  text-align: center;
}
.nama_view {
  font-family: Poppins_bold;
  color: #46ab86;
}
.title-item {
  font-family: Poppins_Regular;
  color: #f03b22;
}
.title-details {
  font-family: Poppins_Regular;
}
@media only screen and (min-width: 500px){
  .small_title {
    display: none;
  }
}
/* Define styles for date cells with events */
.has-event {
  background-color: #ffcc00; /* Example background color */
  color: #fff; /* Example text color */
  border-radius: 50%; /* Example rounded border */
  padding: 4px; /* Example padding */
}

.date-cell {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px; /* Adjust width as needed */
  height: 40px; /* Adjust height as needed */
}
@media only screen and (max-width: 500px) {
  .login_label {
    text-align: center;
  }
  
  .login_label a {
    display: block;
    margin-top: 0.5em; /* Adjust as needed */
  }
}

.page-item {
  padding-left: 0.4em;
}
.total-title {
  font-family: Poppins_Regular;
}
.total-plant {
  font-family: Poppins_bold;
  margin-top: 0.5em;
}

.breadcrumbs {
  float: right;
  margin-right: 15px;
  margin-top: 10px;
}

.breadcrumb-item+.breadcrumb-item::before {
  display: none;
}

.breadcrumb-item a {
  text-decoration: none;
  color: #848484;
  font-size: 15px;
  font-weight: 700;
  font-family: Poppins_regular;
}

.breadcrumb-item.active {
  color: #282828 !important;
  font-size: 15px;
  font-weight: 700;
  font-family: Poppins_regular;
}

.breadcrumb-item:not(.active)::after {
  content: '>';
  margin-left: 5px;
  color: #000000;
  font-size: 15px;
  font-weight: 500;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  opacity: 0.6;
}

.export-icon {
  color:black;
  cursor: pointer;
}

/* CSS for overlay */
.spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent white background */
    z-index: 9999; /* Ensure it appears on top of other elements */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* CSS for loading spinner */
.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1); /* Light gray border */
    border-top: 4px solid #3498db; /* Blue border on top */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite; /* Rotate animation */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Add this style to cover only the chart column */
.chart-column {
  position: relative;
}

.chart-column .spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5); /* Adjust opacity as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}

.chart-column .spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.chart-icon {
  color: orange;
  cursor: pointer;
  font-size: 24px;
}


.table_plantation{
  overflow-x: auto;
}

.table_plantation thead th{
  background-color: #f3f3f3 !important;
  color: black;
  font-family: Poppins_Regular;
  border: none !important;
  white-space: nowrap;
}

.table_plantation tbody td{
  color: #8F9383 !important;
  white-space: nowrap;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #737c04 !important;
  border-color: #737c04 !important;
}

.delete_btn {
  background-color: #dc3545;
  color: white;
  font-family: Poppins_Regular;
  width: 150px;
  margin-right: 2%;
  margin-bottom: 3%;
  box-shadow: 3px 2px 5px #a1a1a1;
  font-family: Poppins_Regular;
  font-weight: 600;
  border-radius: 4px;
  /* height: 32px; */
  letter-spacing: 2px;
  border: none;
  outline: none;
  text-decoration: none;
  text-align: center;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

.highlight {
  background-color: yellow; /* or any color you prefer */
  font-weight: bold; /* optional */
}