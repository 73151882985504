.legend-container {
    /* background-color: rgba(255, 255, 255, 0.8); */
    /* border: 1px solid #ccc; */
    /* padding: 5px; */
    /* border-radius: 5px; */
    /* display: none; */
    position: absolute !important;

    right: 1em;
    top: 1.6em;
  }